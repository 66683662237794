body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'SFRegular';
	src: url('./fonts/SFRegular.ttf') format('truetype');
}

@font-face {
	font-family: 'SFBold';
	src: url('./fonts/SFBold.ttf') format('truetype');
}

@font-face {
	font-family: 'SFThin';
	src: url('./fonts/SFThin.ttf') format('truetype');
}

@font-face {
	font-family: 'SFUltralight';
	src: url('./fonts/SFUltralight.ttf') format('truetype');
}

@font-face {
	font-family: 'SFRegularItalic';
	src: url('./fonts/SanFranciscoText-RegularItalic.otf') format('truetype');
}

@font-face {
	font-family: 'SFLightItalic';
	src: url('./fonts/SanFranciscoText-LightItalic.otf') format('truetype');
}

@font-face {
	font-family: 'SFBoldItalic';
	src: url('./fonts/SanFranciscoText-BoldItalic.otf') format('truetype');
}